import ButtonLink from 'components/button-link/ButtonLink';
import LoadableLogo from 'components/logo/LoadableLogo';
import useMedia from 'hooks/useMedia';
import { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Paths } from 'constants/routes';
import LanguageSelect from 'components/language-select/LanguageSelect';
import { useIsAuthenticated } from '@azure/msal-react';
import Typography from 'components/typography/Typography';
import styles from './Header.module.scss';
import Hamburger from 'assets/svg/hamburger.svg?react';
import NavigationDialog from 'components/navigation-dialog/NavigationDialog';

const Header = () => {
	const { t } = useTranslation();
	const parentElement = useRef(null); // renders the radix modal in the right place
	const [tablet] = useMedia('(max-width: 767.98px)');
	const isAuthenticated = useIsAuthenticated();

	return (
		<header className={styles.rootv2}>
			<Link
				to={Paths.DASHBOARD}
				aria-label={t('MijnStadswonen')}
			>
				<LoadableLogo white={!tablet} />
			</Link>
			<div
				className={styles.contentRightNavigation}
				ref={parentElement}
			>
				<LanguageSelect />
				{isAuthenticated && (
					<NavigationDialog
						refContainer={parentElement.current}
						trigger={(
							<ButtonLink
								contrast
								className={styles.menuTrigger}
								iconRight={Hamburger}
								testid='menuButton'
							>
								<Typography
									variant='body'
									color='white'
									fontWeight='500'
								>
									<Trans>Menu</Trans>
								</Typography>
							</ButtonLink>
						)}
					/>
				)}
			</div>
		</header>
	);
};

export default Header;
