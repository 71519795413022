import clsx from 'clsx';
import styles from './AzureContainer.module.scss';
import Typography from 'components/typography/Typography';
import ButtonLink from 'components/button-link/ButtonLink';
import ArrowBackward from 'assets/svg/arrow-backward.svg?react';
import ArrowForward from 'assets/svg/arrow-forward.svg?react';
import Logo from 'components/logo/Logo';
import { ReactNode } from 'react';
import Select from 'components/select/Select';
import { safeEnv } from 'utils/safeEnv';
import { getExternalLink } from 'utils/getExternalLink';

export type Props = {
	children: ReactNode;
	title?: string;
	backLink?: string;
	backText?: string;
	variant?: 'single';
	languageSelectComponent?: ReactNode;
	env?: string;
	testid?: string;
}

const AzureContainer = ({
	children,
	title,
	backLink,
	backText,
	variant,
	languageSelectComponent,
	env,
	testid,
}: Props) => {
	const boxClasses = clsx(styles.box, {
		[`${styles[`box--variant-${variant}`]}`]: variant !== undefined,
	});

	return (
		<div className={styles.login}>
			<div className={styles.background}>
				<div className={styles.backgroundShapes} />
			</div>
			<div className={styles.root}>
				<div className={styles.background}>
					<div className={styles.backgroundShapes}>
						<span className={styles.bal} />
						<span className={styles.bal} />
						<span className={styles.bal} />
						<span className={styles.bal} />
					</div>
				</div>

				<div className={styles.header}>
					<div className={styles.headerContent}>
						{(backLink && backText) ? (
							<ButtonLink
								size='large'
								iconLeft={ArrowBackward}
								link={backLink}
								contrast
								rel='noreferrer'
								className={`${backLink !== safeEnv('REACT_APP_WEBSITE') ? 'locale-link' : undefined} replace-client-id-link`}
							>
								<span dangerouslySetInnerHTML={{ __html: backText }} />
							</ButtonLink>
						) : (
							<span />
						)}
						<div className={styles.headerRight}>
							{languageSelectComponent ? languageSelectComponent : (
								<Select
									id='language'
									name='language'
									value={''}
									onChange={() => {
										return null;
									}}
									options={[
										{
											key: 'nl', value: 'nl-NL', disabled: false, label: 'NL',
										},
										{
											key: 'en', value: 'en-US', disabled: false, label: 'EN',
										},
									]}
									placeholder={'Kies een taal'}
									transparent
								/>
							)}
							<Logo />
						</div>
					</div>
				</div>

				<div className={styles.content}>
					<div className={boxClasses}>
						<div className={'grid-1-lg'}>
							{title && (
								<Typography
									type='h1'
									variant='page-title'
									color='white'
									dangerouslySetInnerHTML={title}
									data-testid={testid}
								/>
							)}
							{children}
						</div>
					</div>
				</div>
				<nav className={styles.bottomNavigation}>
					<ButtonLink
						className={styles.bottomNavigationItem}
						iconRight={ArrowForward}
						hoverAnimation='bounce-right'
						fontWeight='normal'
						link={getExternalLink('LOGIN_PARTNER', undefined, env)}
					>
						Voor partners
					</ButtonLink>
					<ButtonLink
						className={styles.bottomNavigationItem}
						iconRight={ArrowForward}
						hoverAnimation='bounce-right'
						fontWeight='normal'
						link={getExternalLink('LOGIN_EMPLOYEE', undefined, env)}
					>
						Voor medewerkers
					</ButtonLink>
				</nav>
			</div>
		</div>
	);
};

export default AzureContainer;
