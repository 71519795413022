import clsx from 'clsx';
import { AriaAttributes, FunctionComponent, ReactNode } from 'react';
import styles from './Typography.module.scss';
import { DataTestID } from 'model';

export interface TypographyProps extends DataTestID, AriaAttributes {
	className?: string;
	fontWeight?: 'normal' | 'bold' | '400' | '500' | '600' | '700' | '800' | '900';
	dangerouslySetInnerHTML?: string;
	children?: ReactNode;
	type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'label' | 'span';
	variant:
	| 'page-title-large'
	| 'page-title'
	| 'block-title'
	| 'button-label'
	| 'body-title'
	| 'body'
	| 'body-small'
	| 'form-label'
	| 'form-description'
	| 'tag'
	| 'checkbox-label';
	color?: 'purple-300' | 'purple-200' | 'purple-100' | 'blue-400' | 'error' | 'red-300' | 'green-300' | 'white' | 'orange-300' | 'navy';
	htmlFor?: string;
	id?: string;
	siblingSpacing?: boolean;
}

const Typography: FunctionComponent<TypographyProps> = ({
	children,
	className,
	fontWeight = 'normal',
	type = 'p',
	variant,
	color,
	siblingSpacing = false,
	dangerouslySetInnerHTML,
	htmlFor,
	id,
	...dataAttributes
}) => {
	const classes = clsx(styles.typography, styles[`typography--variant-${variant}`], className, {
		[styles['typography--sibling-spacing']]: siblingSpacing,
		[styles[`typography--color-${color}`]]: color,
		[styles[`typography--font-weight-${fontWeight}`]]: fontWeight !== undefined && fontWeight !== 'normal',
	});
	const Component = htmlFor !== undefined ? 'label' : type;

	if (dangerouslySetInnerHTML) {
		return (
			<Component
				{...dataAttributes}
				id={id}
				className={classes}
				dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }}
				htmlFor={htmlFor}
			/>
		);
	}

	return (
		<Component
			{...dataAttributes}
			id={id}
			className={classes}
			htmlFor={htmlFor}
			lang='nl'
		>
			{children}
		</Component>
	);
};

export default Typography;
