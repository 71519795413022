import { LANGUAGE_STORAGE_KEY } from 'constants/index';
import dayjs from 'dayjs';
import safeWindow from 'utils/safeWindow';

export enum Locale {
	NL = 'nl-NL',
	EN = 'en-US'
}

type LongDateFormat =
	| 'LT'
	| 'LTS'
	| 'L'
	| 'LL'
	| 'LLL'
	| 'LLLL';

const localeFormatMap: Record<'en' | 'nl', Record<LongDateFormat, string>> = {
	en: {
		LTS: 'h:mm:ss A',
		LT: 'h:mm A',
		L: 'MM-DD-YYYY',
		LL: 'MMMM D, YYYY',
		LLL: 'MMMM D, YYYY h:mm A',
		LLLL: 'dddd, MMMM D, YYYY h:mm A',
	},
	nl: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD-MM-YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY HH:mm',
		LLLL: 'dddd D MMMM YYYY HH:mm',
	},
};

export const longDateFormat = (format: LongDateFormat) => {
	const locale = dayjs.locale() as 'en' | 'nl';
	return localeFormatMap[locale][format];
};

export const BE_DATE_FORMAT = 'DD-MM-YYYY';

/**
 * https://day.js.org/docs/en/display/format
 */

export const dayMonthFormat = () => {
	switch (dayjs.locale()) {
		case 'en':
			return 'MMMM D';
		case 'nl':
		default:
			return 'D MMMM';
	}
};

export const setLocale = (locale = 'nl') => {
	switch (locale) {
		case Locale.EN:
		case 'en':
			dayjs.locale('en');
			break;
		case Locale.NL:
		case 'nl':
		default:
			dayjs.locale('nl');
			break;
	}
};

export const localLng = (): string => {
	const storageLng = safeWindow?.localStorage?.getItem(LANGUAGE_STORAGE_KEY);
	if (storageLng) {
		switch (storageLng) {
			case Locale.EN:
			case 'en':
				setLocale('en');
				return Locale.EN;
			case Locale.NL:
			case 'nl':
			default:
				setLocale('nl');
				return Locale.NL;
		}
	}

	setLocale('nl');
	return Locale.NL;
};
