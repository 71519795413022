import { localLng } from 'constants/locale';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enUS from './en-US.json';
import nlNL from './nl-NL.json';

const lng = localLng();

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			'nl-NL': { translation: nlNL },
			'en-US': { translation: enUS },
		},
		// defaultNS: 'frontend',
		// ns: 'frontend',
		fallbackLng: lng,
		lng,
		load: 'currentOnly',

		nsSeparator: false,
		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		react: {
			transEmptyNodeValue: '', // what to return for empty Trans
			transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
			transKeepBasicHtmlNodesFor: [], // don't convert to <1></1> if simple react elements
			useSuspense: false,
		},
	});

export default i18n;
