import { useLanguageSelector } from 'components/language-provider/LanguageProvider';
import { externalLink, getExternalLink } from 'utils/getExternalLink';

type ExternalLinkKeys = keyof typeof externalLink;

const useExternalLink = (link: ExternalLinkKeys) => {
	const { language } = useLanguageSelector();
	const externalLink = getExternalLink(link, language);

	return externalLink;
};

export default useExternalLink;
