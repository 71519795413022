import { OpenAPIConfig } from '../../../Shared.Frontend/generated-portal-stadswonen/core/OpenAPI';
import { OpenAPI as OpenAPIPortal } from '../../../Shared.Frontend/generated-portal/core/OpenAPI';
import { OpenAPI as OpenAPIPortalBE } from '../../../Shared.Frontend/generated-portal-stadswonen/core/OpenAPI';
import { OpenAPI as OpenAPIContentDeliveryWRF } from '../../../Shared.Frontend/generated-content-delivery-wrf/core/OpenAPI';
import { OpenAPI as OpenAPIMaintenance } from '../../../Shared.Frontend/generated-maintenance/core/OpenAPI';
import { OpenAPI as OpenAPIAvailability } from '../../../Shared.Frontend/generated-availability/core/OpenAPI';
import { OpenAPI as OpenAPIAccount } from '../../../Shared.Frontend/generated-account/core/OpenAPI';
import { OpenAPI as OpenAPIPayment } from '../../../Shared.Frontend/generated-payment/core/OpenAPI';

export const openAPISet = <Property extends keyof OpenAPIConfig>(
	property: Property,
	value: OpenAPIConfig[Property],
) => {
	OpenAPIPortal[property] = value;
	OpenAPIPortalBE[property] = value;
	OpenAPIContentDeliveryWRF[property] = value;
	OpenAPIMaintenance[property] = value;
	OpenAPIAvailability[property] = value;
	OpenAPIAccount[property] = value;
	OpenAPIPayment[property] = value;
};

export const openAPIMutate = <Property extends keyof OpenAPIConfig>(
	property: Property,
	mutator: (value: OpenAPIConfig[Property]) => OpenAPIConfig[Property],
) => {
	OpenAPIPortal[property] = mutator(OpenAPIPortal[property]);
	OpenAPIPortalBE[property] = mutator(OpenAPIPortalBE[property]);
	OpenAPIContentDeliveryWRF[property] = mutator(OpenAPIContentDeliveryWRF[property]);
	OpenAPIMaintenance[property] = mutator(OpenAPIMaintenance[property]);
	OpenAPIAvailability[property] = mutator(OpenAPIAvailability[property]);
	OpenAPIAccount[property] = mutator(OpenAPIAvailability[property]);
	OpenAPIPayment[property] = mutator(OpenAPIPayment[property]);
};
