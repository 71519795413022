import clsx from 'clsx';
import { FunctionComponent, ReactChild, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import './Modal.scss';
import { ModalContext } from './useModal';

type ModalPosition = 'bottom';

interface Props {
	open: boolean;
	onClose: () => void;
	className?: string;
	children: ReactChild;
	position?: ModalPosition;
}
/**
 * @deprecated use Radix UI's Dialog instead
 */
const Modal: FunctionComponent<Props> = ({ open, children, className, onClose, position }) => {
	const [openState, setOpenState] = useState<boolean>(open);

	const onExit = useCallback(() => {
		setOpenState(false);
	}, [setOpenState]);

	useEffect(() => {
		if (!openState && open) {
			setOpenState(true);
		}
	}, [open, openState, setOpenState]);

	if (!openState) {
		return null;
	}

	const classes = clsx('modal', className);
	const modalContentClasses = clsx('modal__content', {
		'modal__position-bottom': position === 'bottom',
	});

	return createPortal(
		<ModalContext.Provider value={{ closeModal: onClose }}>
			<div className={classes}>
				<CSSTransition
					appear
					in={open}
					unmountOnExit
					mountOnEnter
					classNames='modal__backdrop--transition'
					timeout={{ enter: 400, exit: 200 }}
				>
					<div
						className='modal__backdrop'
						onClick={onClose}
					/>
				</CSSTransition>
				<CSSTransition
					appear
					in={open}
					unmountOnExit
					mountOnEnter
					onExited={onExit}
					classNames={`${modalContentClasses} modal__content--transition`}
					timeout={{ enter: 400, exit: 200 }}
				>
					{children}
				</CSSTransition>
			</div>
		</ModalContext.Provider>,
		document.body,
	);
};

export default Modal;
