import { Paths } from 'constants/routes';
import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAccount } from '@azure/msal-react';
import safeWindow from 'utils/safeWindow';
import { enrolledStatusValue, useIsEnrolled } from './store/useEnrollment';

const useNewCustomerRouting = () => {
	const account = useAccount();
	const history = useHistory();

	const { data, isError, isLoading } = useIsEnrolled();

	const doNotRedirectToSignupFlow = useRouteMatch([
		Paths.SIGNUP_WELCOME,
		Paths.SIGNUP_DETAILS,
		Paths.HUURCONTRACT_KOPPELEN,
		Paths.HUURCONTRACT_KOPPELEN_CONFIRMATION,
		Paths.LOGGED_OUT,
		Paths.LOGIN_FAILED,
	]);

	const doRedirectAwayFromSignupFlow = useRouteMatch([
		Paths.SIGNUP_WELCOME,
		Paths.SIGNUP_DETAILS,
	]);

	// Redirect away from signup flow if is enrolled
	useEffect(() => {
		if (isLoading || !doRedirectAwayFromSignupFlow) return;
		if ((enrolledStatusValue(data) === 'ENROLLED' || enrolledStatusValue(data) === 'WAITING_FOR_PAYMENT') && !isError) {
			history.replace(Paths.DASHBOARD);
		}

	}, [data, doRedirectAwayFromSignupFlow, history, isError, isLoading]);

	// Redirect to signup flow if not enrolled
	useEffect(() => {
		if (isLoading || doNotRedirectToSignupFlow || !account) return;

		// If account is already a customer / has contracts - do not make them signup
		if (account?.idTokenClaims?.extension_Roles?.includes('customer')) return;

		// If the payment has failed, it will have error in the url
		// Do not redirect to signup flow directly, but show the error message first. Then redirect to signup flow within the welcome dialog
		const searchParams = new URLSearchParams(safeWindow.location?.search);
		const result = searchParams.get('result') as 'success' | 'failure' | null;

		if (result === 'failure') return;

		if (enrolledStatusValue(data) === 'NOT_ENROLLED' || isError) {
			history.replace(`${Paths.SIGNUP_WELCOME}`);
		}
	}, [
		history,
		data,
		isError,
		isLoading,
		account,
		doNotRedirectToSignupFlow,
	]);
};

export default useNewCustomerRouting;
