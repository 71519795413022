import { useCallback, useEffect, useMemo, useState } from 'react';

type HookReturn = [boolean | undefined];

type Props = (query: string) => HookReturn;

const useMedia: Props = (query) => {
	const [matches, setMatches] = useState<boolean | undefined>(undefined);
	const media = useMemo(() => {
		const media = window.matchMedia(query);
		if (media.matches !== matches) setMatches(media.matches);
		return media;
	}, [query, matches]);

	const listener = useCallback(() => setMatches(media.matches), [media]);
	useEffect(() => {
		media.addListener(listener);
		return () => media.removeListener(listener);
	}, [media, listener]);

	return [matches];
};

export default useMedia;
