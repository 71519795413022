import clsx from 'clsx';
import { ChangeEvent, useCallback, useState, useMemo, useRef, forwardRef } from 'react';
import './Select.scss';
import ChevronDown from 'assets/svg/chevron-down.svg?react';
import HelperText from 'components/helper-text/HelperText';

export interface SelectOption {
	value: string;
	label: string;
	disabled?: boolean;
	key?: string;
}

interface Props {
	className?: string;
	value?: string;
	placeholder?: string;
	id: string;
	testid?: string;
	options: SelectOption[];
	name: string;
	disabled?: boolean;
	valid?: boolean;
	helperText?: string;
	onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
	onBlur?: (event: ChangeEvent<HTMLSelectElement>) => void;
	transparent?: boolean;
}

const Select = forwardRef((
	{
		value,
		className,
		placeholder,
		id,
		name,
		onChange,
		helperText,
		onBlur,
		valid,
		disabled = false,
		transparent = false,
		options,
		testid,
	}: Props,
	// Disabling this rule - not making effor to fix with move to design system in mind
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ref: any,
) => {
	const [focus, setFocus] = useState<boolean>(false);
	const innerRef = useRef<HTMLSelectElement>(null);

	const onFocus = useCallback(() => setFocus(true), []);
	const onInnerBlur = useCallback(
		(event: ChangeEvent<HTMLSelectElement>) => {
			setFocus(false);
			if (onBlur !== undefined) {
				onBlur(event);
			}
		},
		[onBlur],
	);

	const renderedOptions = options.map(({ value, label, disabled = false }) => (
		<option
			key={`${label}${value}`}
			value={value}
			disabled={disabled}
		>
			{label}
		</option>
	));

	const selected = options.find((option) => option.value === value);

	const helperTextView = useMemo(() => {
		if (helperText !== undefined) {
			const status = valid === undefined ? undefined : valid ? 'success' : 'error';
			const classes = clsx('select__helper-text', {
				[`select__helper-text--${status}`]: status !== undefined,
			});
			return (
				<HelperText
					className={classes}
					id={id}
				>
					{helperText}
				</HelperText>
			);
		}

		return undefined;
	}, [helperText, valid, id]);

	const classes = clsx('select', className, {
		'select--focus': focus,
		'select--disabled': disabled,
		'select--transparent': transparent,
		'select--success': valid !== undefined && valid,
		'select--error': valid !== undefined && !valid,
		'select--has-value': selected !== undefined,
	});
	const fieldClasses = clsx('select__field select__field--set-width');

	return (
		<span
			ref={ref}
			className={classes}
		>
			<label
				htmlFor={id}
				className='select__inner'
			>
				<span className='select__label'>{selected ? selected.label : placeholder}</span>
				<select
					ref={innerRef}
					id={id}
					name={name}
					value={value}
					onChange={onChange}
					className={fieldClasses}
					onFocus={onFocus}
					onBlur={onInnerBlur}
					disabled={disabled}
					data-testid={testid}
				>
					<option
						disabled
						value=''
					>
						{placeholder}
					</option>
					{renderedOptions}
				</select>
				<ChevronDown
					className='select__icon'
				/>
			</label>
			{helperTextView}
		</span>
	);
});

Select.displayName = 'Select';

export default Select;
