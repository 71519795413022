import { LANGUAGE_STORAGE_KEY } from 'constants/index';
import { Locale, localLng, setLocale } from 'constants/locale';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie'; // Peer dep of react-cookie
import 'dayjs/locale/nl';
import 'dayjs/locale/en';


type LanguageSelectorContextProps = {
	language: string;
	setLanguage: (language: string) => void;
}

const cookies = new Cookies();
// The stadswonen public site places a cookie to store language preference
// This helps to keep the language preference across platforms
const languageCookie = cookies.get('language-cookie');

export const LanguageSelectorContext = createContext<LanguageSelectorContextProps>({
	language: languageCookie === 'en' ? Locale.EN : Locale.NL,
	setLanguage: () => { },
});

type Props = {
	children: ReactNode;
}

export const LanguageProvider = ({ children }: Props) => {
	const { i18n } = useTranslation();
	const [language, setLanguage] = useState<string>(languageCookie === 'en' ? Locale.EN : i18n.language);

	useEffect(() => {
		if (i18n.language !== language) {
			// Keep the language in sync with i18n
			i18n.changeLanguage(language);
			cookies.set('language-cookie', language);

			// Set the locale for dayjs
			if (language === Locale.NL) {
				setLocale('nl');
			} else {
				setLocale('en');
			}
		} else {
			localLng();
		}

		localStorage.setItem(LANGUAGE_STORAGE_KEY, language);
	}, [language, i18n]);

	return (
		<LanguageSelectorContext.Provider value={{ language, setLanguage }}>{children}</LanguageSelectorContext.Provider>
	);
};

export const useLanguageSelector = () => {
	const { language, setLanguage } = useContext(LanguageSelectorContext);

	useEffect(() => {
		const html = document.querySelector('html');
		if (html) {
			html.setAttribute('lang', language.split('-')[0]);
		}
	}, [language]);

	return { language, setLanguage };
};
