import House from 'assets/svg/rich/house.svg?react';
import Parking from 'assets/svg/rich/parking.svg?react';
import { ContractType } from 'constants/index';
import i18next from 'i18next';
import { ReactChild } from 'react';

export const contractTypeTitle = (type: ContractType | string | undefined) => {
	switch (type) {
		case ContractType.HOME:
			return i18next.t('Woonruimte');
		case ContractType.PARKING:
			return i18next.t('Parkeerplaats');
		default:
			return '';
	}
};

type ContractTypeIcon = (type?: string, className?: string) => ReactChild;

export const contractTypeIcon: ContractTypeIcon = (type, className) => {
	switch (type) {
		case ContractType.HOME:
			return <House className={className} />;
		case ContractType.PARKING:
			return <Parking className={className} />;
		default:
			return <span />;
	}
};

export const contractTypeIconSVG = (type?: string) => {
	switch (type) {
		case ContractType.HOME:
			return House;
		case ContractType.PARKING:
			return Parking;
		default:
			return null;
	}
};
