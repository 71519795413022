import { useAccount } from '@azure/msal-react';
import * as Dialog from '@radix-ui/react-dialog';
import Close from 'assets/svg/close.svg?react';
import LogoLight from 'assets/svg/logo-light.svg?react';
import { parseMsalUrl } from 'auth/config';
import Box from 'components/box/Box';
import ButtonLink from 'components/button-link/ButtonLink';
import { contractTypeTitle } from 'constants/contract';
import { Paths } from 'constants/routes';
import { useContracts } from 'hooks/store/useContracts';
import { useUserProfile } from 'hooks/store/useUserProfile';
import { useContractToUse } from 'hooks/useContractToUse';
import useLockedBody from 'hooks/useLockedBody';
import { useLogoutAction } from 'hooks/useLogout';
import useMedia from 'hooks/useMedia';
import { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { safeEnv } from 'utils/safeEnv';
import styles from './NavigationDialog.module.scss';
import useExternalLink from 'hooks/useExternalLink';
import { useQueryClient } from '@tanstack/react-query';
import { BoxLink, SwFlowsEMailvoorkeuren, SwFlowsHuurcontractKoppelen, SwFlowsMijnGegevens, SwFlowsUitloggen, SwFlowsUrgentie, SwFlowsWachtwoordWijzigen, SwOrangeEMail, SwOrangeHuurwoning, SwOrangeInformatie, SwOrangeStoring, SwOrangeWoning, Typography } from '@woonstad/design-system';
import { getExternalLink } from 'utils/getExternalLink';
import { useIsEnrolled } from 'hooks/store/useEnrollment';

interface Props {
	trigger: ReactNode,
	refContainer?: HTMLElement | null;
}

const NavigationDialog: FunctionComponent<Props> = ({ trigger, refContainer }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [open, setOpen] = useState(false);
	const logoutClick = useLogoutAction();
	const [mobile] = useMedia('(max-width: 500px)');
	const { contracts } = useContracts();
	const history = useHistory();
	const { setContractInStorage } = useContractToUse();
	const { name, email } = useUserProfile();
	const [, setLocked] = useLockedBody(false);
	const contact = useExternalLink('CONTACT');

	const account = useAccount();
	const multiUser = (account?.idTokenClaims?.extension_CustomerIds?.split(',')?.length ?? 0) > 1;

	const handleOpenChange = useCallback((isOpen: boolean) => {
		setOpen(isOpen);
		setLocked(isOpen);
	}, [setLocked]);

	useEffect(() => {
		handleOpenChange(false);
		setLocked(false);
	}, [history.location, handleOpenChange, setLocked]);

	const handleSelectContract = (id: number | undefined) => {
		handleOpenChange(false);
		setLocked(false);
		setContractInStorage(id?.toString());
		queryClient.invalidateQueries();
	};

	const { isEnrolled } = useIsEnrolled();

	return (
		<>
			<CSSTransition
				in={open}
				timeout={200}
				classNames={{
					enter: styles.overlayEnter,
					enterActive: styles.overlayEnterActive,
					exit: styles.overlayExit,
					exitActive: styles.overlayExitActive,
				}}
				unmountOnExit
			>
				<div
					className={styles.overlay}
				/>
			</CSSTransition>
			<Dialog.Root
				open={open}
				onOpenChange={handleOpenChange}
				modal={mobile}
			>
				<Dialog.Trigger asChild>
					{trigger}
				</Dialog.Trigger>

				<Dialog.Portal container={!mobile ? refContainer : undefined}>
					<Dialog.Overlay
						className={styles.overlay}
						forceMount={true}
						asChild
					/>
					<Dialog.Content className={`text-base-primary-on-light ${styles.dialog}`}>
						<Box
							variant={!mobile ? 'soft-dark' : 'light'}
							noPadding
						>
							<div className='grid-1-md pb-6'>
								<div className={styles.header}>
									<LogoLight className={styles.logo} />
									<Dialog.Close asChild>
										<ButtonLink
											className={styles.menuTrigger}
											iconRight={Close}
										>
											<Typography
												variant='label-regular'
											>
												<Trans>Sluit</Trans>
											</Typography>
										</ButtonLink>
									</Dialog.Close>
								</div>

								<div className='grid-1-md p-6'>
									{isEnrolled && (
										<>
											<Typography
												variant='title-body'
											>
												<Trans>Reacties en aanbod</Trans>
											</Typography>
											<BoxLink
												iconLeft={SwOrangeStoring}
											>
												<Link
													to={Paths.REACTIONS}
													data-testid='reactiesOverzichtLink'
												>
													<Trans>Reacties overzicht</Trans>
												</Link>
											</BoxLink>
										</>
									)}

									<BoxLink
										iconLeft={SwOrangeWoning}
									>
										<a
											href={getExternalLink('OFFERS')}
											target='_blank'
											rel='noreferrer'
											data-testid='bekijkHetAanbodLink'
										>
											<Trans>Bekijk het aanbod</Trans>
										</a>
									</BoxLink>

									{isEnrolled && (
										<BoxLink
											iconLeft={SwFlowsUrgentie}
										>
											<Link
												to={Paths.REQUEST_URGENCY}
												data-testid='urgentieAanvragenLink'
											>
												<Trans>Urgentie aanvragen</Trans>
											</Link>
										</BoxLink>
									)}
								</div>

								{isEnrolled && (
									<div className='grid-1-md p-6'>
										<Typography
											variant='title-body'
										>
											<Trans>Berichten</Trans>
										</Typography>
										<BoxLink
											iconLeft={SwOrangeEMail}
										>
											<Link
												to={Paths.NOTIFICATIONS}
												data-testid='berichtenVoorJouLink'
											>
												<Trans>Berichten voor jou</Trans>
											</Link>
										</BoxLink>
									</div>
								)}

								{(isEnrolled && contracts && contracts.length > 0) && (
									<div className='grid-1-md p-6'>
										<Typography
											variant='title-body'
										>
											<Trans>Contracten</Trans>
										</Typography>
										{contracts.map((contract) => (
											<BoxLink
												key={contract?.id}
												iconLeft={SwOrangeHuurwoning}
												tag={contractTypeTitle(contract?.type)}
												subtext={`${contract?.zipCode} ${contract?.city}`}
											>
												<button
													onClick={() => handleSelectContract(contract?.id)}
													data-testid='contractButton'
												>
													{contract?.address}
												</button>
											</BoxLink>
										))}
									</div>
								)}

								<div className='grid-1-md p-6'>
									<div className='grid-1-sm'>
										<Typography
											variant='title-body'
										>
											<Trans>Account</Trans>
										</Typography>
										{isEnrolled && (
											<>
												{name ? (
													<p
														className='prose prose-a:font-[400] prose-a:text-base-primary-on-light text-base-primary-on-light'
														data-testid='accountInfo'
													>
														<Typography
															variant='content-body'
															type='span'
															dangerouslySetInnerHTML={t('Ingelogd als {{name}}, met e-mailadres {{email}}', { name, email })}
														/>
														{multiUser && (
															<>
																{' '}
																<Typography
																	variant='content-body'
																	type='span'
																>
																	<a
																		href={Paths.CHOOSE_ACCOUNT}
																		data-testid='changeUser'
																	>
																		{t('(wissel van gebruiker)')}
																	</a>
																</Typography>
															</>
														)}
													</p>
												) : (
													<div>
														<Typography
															variant='content-body'
															dangerouslySetInnerHTML={t('Ingelogd met e-mailadres {{email}}', { email })}
														/>
														{multiUser && (
															<Typography
																variant='content-body'
															>
																<a href={Paths.CHOOSE_ACCOUNT}>{t('(wissel van gebruiker)')}</a>
															</Typography>
														)}
													</div>
												)}
											</>
										)}
									</div>

									{isEnrolled && (
										<BoxLink
											iconLeft={SwFlowsMijnGegevens}
										>
											<Link
												to={Paths.USER_PROFILE}
												data-testid='mijnGegevensLink'
											>
												<Trans>Mijn gegevens</Trans>
											</Link>
										</BoxLink>
									)}

									<BoxLink
										iconLeft={SwFlowsWachtwoordWijzigen}
									>
										<a
											href={parseMsalUrl(safeEnv('B2C_CHANGE_PASSWORD') ?? '')}
											data-testid='wachtwoordWijzigenLink'
										>
											<Trans>Wachtwoord wijzigen</Trans>
										</a>
									</BoxLink>

									{isEnrolled && (
										<BoxLink
											iconLeft={SwFlowsEMailvoorkeuren}
										>
											<Link
												to={Paths.MAIL_PREFERENCES}
												data-testid='emailvoorkeurenLink'
											>
												<Trans>E-mailvoorkeuren</Trans>
											</Link>
										</BoxLink>
									)}

									{!contracts || contracts.length <= 0 && (
										<BoxLink
											iconLeft={SwFlowsHuurcontractKoppelen}
										>
											<Link
												to={Paths.HUURCONTRACT_KOPPELEN}
												data-testid='huurcontractKoppelenLink'
											>
												<Trans>Huurcontract koppelen</Trans>
											</Link>
										</BoxLink>
									)}

									<BoxLink
										iconLeft={SwFlowsUitloggen}
									>
										<button
											onClick={logoutClick}
											data-testid='uitloggenButton'
										>
											<Trans>Uitloggen</Trans>
										</button>
									</BoxLink>
								</div>

								<div className='grid-1-md p-6'>
									<Typography
										variant='title-body'
									>
										<Trans>Klantenservice</Trans>
									</Typography>
									<BoxLink
										iconLeft={SwOrangeInformatie}
									>
										<a
											href={contact}
											data-testid='contactLink'
										>
											<Trans>Neem contact met ons op</Trans>
										</a>
									</BoxLink>
								</div>
							</div>
						</Box>
					</Dialog.Content>
				</Dialog.Portal>
			</Dialog.Root>
		</>
	);
};

export default NavigationDialog;
