import Footer from 'components/footer/Footer';
import Header from 'components/header/Header';
import { ReactNode } from 'react';
import styles from './PageRoutesContainer.module.scss';

interface Props {
	children: ReactNode;
}

const PageRoutesContainer = ({ children }: Props) => {
	return (
		<div className={styles.root}>

			<Header />

			<div className={styles.content}>
				{children}
			</div>

			<Footer />
		</div>
	);
};

export default PageRoutesContainer;
