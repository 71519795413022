import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { UserService } from '../../../../Shared.Frontend/generated-portal-stadswonen/services/UserService';
import { UserProfile } from '../../../../Shared.Frontend/generated-portal-stadswonen/models/UserProfile';
import getDomain from '../../../../Shared.Frontend/utils/getDomain';
import { useCookies } from 'react-cookie';

export let identityChosen: UserProfile | null = null;

export const useChooseAccount = () => {
	const [, setCookie] = useCookies(['customer-id']);

	const { data, isLoading } = useQuery({
		queryKey: ['user-me'],
		queryFn: UserService.userMe,
	});

	const setIdentity = useCallback((index: number) => {
		if (!data?.userProfiles) return;

		const customerId = data.userProfiles[index]?.customer?.id;
		const domain = `.${getDomain(location.host)}`;

		setCookie('customer-id', customerId, {
			path: '/',
			domain: domain,
			...(domain.includes('localhost') ? {} : { secure: true }), // Only set secure cookie on production (not localhost)
			// Not using httponly here to check later if cookie is present
		});

		identityChosen = data?.userProfiles[index];
	}, [
		data?.userProfiles,
		setCookie,
	]);

	return {
		userProfiles: data?.userProfiles?.sort((a, b) => (a.initials ?? '').localeCompare(b.initials ?? '')),
		setIdentity,
		isLoading,
	};
};
