import Select from 'components/select/Select';

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Locale } from 'constants/locale';
import { useLanguageSelector } from 'components/language-provider/LanguageProvider';

const options = [
	{ label: 'NL', value: Locale.NL },
	{ label: 'EN', value: Locale.EN },
];

const LanguageSelect: FunctionComponent = () => {
	const { t } = useTranslation();

	const { language, setLanguage } = useLanguageSelector();

	return (
		<Select
			id='language'
			name='language'
			value={language}
			onChange={(event) => setLanguage(event.target.value)}
			options={options}
			placeholder={t('Kies een taal')}
			transparent
		/>
	);
};

export default LanguageSelect;
