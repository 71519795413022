import BeforeUnloadModal from 'components/before-unload-modal/BeforeUnloadModal';
import { useLanguageSelector } from 'components/language-provider/LanguageProvider';
import Modal from 'components/modal/Modal';
import { useModal } from 'components/modal/useModal';
import { ENGLISH_TO_WSR_STORAGE_KEY, ENGLISH_TO_WSR_STORAGE_VALUE } from 'constants/index';
import { Locale } from 'constants/locale';
import { AnchorHTMLAttributes, forwardRef, ReactNode, Ref, useMemo } from 'react';
import safeLocalStorage from 'utils/safeLocalstorage';

type Attributes = AnchorHTMLAttributes<HTMLAnchorElement>;

export interface AnchorProps extends Attributes {
	disableModal?: boolean;
	children: ReactNode;
}

const Anchor = forwardRef(
	({ disableModal, children, href = '', target, ...props }: AnchorProps, ref?: Ref<HTMLAnchorElement>) => {
		const localStorageShowMessage = safeLocalStorage.getItem?.(ENGLISH_TO_WSR_STORAGE_KEY);
		const { language } = useLanguageSelector();

		const isDomain = useMemo(() => (
			/^(http:|https:|)[/][/]([^/]+[.])*woonstadrotterdam\.nl/.test(href) || /(http:|https:|)[/][/](stadswonenrotterdam.nl)/.test(href)
		), [href]);
		const [isModalOpen, openModal, closeModal] = useModal(false);

		if (disableModal || !isDomain) {
			return (
				<a
					href={href}
					target={target}
					{...props}
					ref={ref}
				>
					{children}
				</a>
			);
		}

		if (isDomain && localStorageShowMessage !== ENGLISH_TO_WSR_STORAGE_VALUE && language === Locale.EN) {
			return (
				<>
					<a
						onClick={openModal}
						{...props}
						ref={ref}
					>
						{children}
					</a>
					<Modal
						open={isModalOpen}
						onClose={closeModal}
						position='bottom'
					>
						<BeforeUnloadModal
							href={href}
							target={target}
							handleClose={closeModal}
						/>
					</Modal>
				</>
			);
		}

		return (
			<a
				href={href}
				target={target}
				{...props}
				ref={ref}
			>
				{children}
			</a>
		);
	},
);

Anchor.displayName = 'Anchor';

export default Anchor;
