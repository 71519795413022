import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import 'dayjs/locale/en';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const setupDayjs = () => {
	dayjs.extend(isSameOrBefore);
	dayjs.extend(isBetween);
	dayjs.extend(customParseFormat);
};

export default setupDayjs;
