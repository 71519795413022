/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticationResponse } from '../models/AuthenticationResponse';
import type { UserProfile } from '../models/UserProfile';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Retrieve authenticated user and refresh token
     * @returns AuthenticationResponse OK
     * @throws ApiError
     */
    public static userMe(): CancelablePromise<AuthenticationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/user/me',
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * Retrieve profile for authenticated user
     * @returns UserProfile OK
     * @throws ApiError
     */
    public static userGetProfile(): CancelablePromise<UserProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stadswonen-portal/api/user/profile',
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * Update profile for authenticated user
     * @param updates
     * @returns boolean OK
     * @throws ApiError
     */
    public static userUpdateProfile(
        updates: Record<string, any>,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/stadswonen-portal/api/user/profile',
            body: updates,
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

}
