import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DataService } from '../../../../Shared.Frontend/generated-portal-stadswonen/services/DataService';

export const useDataConfig = () => {
	const { data } = useQuery({
		queryKey: ['data-config'],
		queryFn: DataService.dataConfig,
	});

	return data;
};

type UseOnlineAfspraken = (key: 'medebewoneraanmelden' | 'sleutelaanvragen' | 'huuropzeggen') => string | undefined;

export const useOnlineAfspraken: UseOnlineAfspraken = (key) => {
	const config = useDataConfig();

	const url = useMemo(() => {
		const enabled = config?.onlineafspraken && config?.onlineafspraken[key] !== undefined ? config?.onlineafspraken[key] === 'true' : undefined;
		const url =
			config?.onlineafspraken && config?.onlineafspraken[`${key}_url`] !== undefined
				? config?.onlineafspraken[`${key}_url`]?.trim()
				: undefined;
		return enabled ? url : undefined;
	}, [config?.onlineafspraken, key]);

	return url;
};

export const usePortal = () => {
	const config = useDataConfig();

	const enableInvoiceRequest = useMemo(() => (
		config?.portal?.['enable-invoice-request'] === 'true' ? true : false
	), [config?.portal?.['enable-invoice-request']]);

	const enableRentSpecificationRequest = useMemo(() => (
		config?.portal?.['enable-rent-specification-request'] === 'true' ? true : false
	), [config?.portal?.['enable-rent-specification-request']]);

	return [enableInvoiceRequest, enableRentSpecificationRequest];
};


export const useShowSwitch = () => {
	const config = useDataConfig();
	if (config?.translations?.showSwitch === 'false') {
		return false;
	}

	return true;
};
