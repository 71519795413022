import { Trans } from 'react-i18next';
import styles from './Footer.module.scss';
import ArrowForward from 'assets/svg/arrow-forward.svg?react';
import InfoBlue from 'assets/svg/rich/info-blue.svg?react';
import useExternalLink from 'hooks/useExternalLink';
import { TextLink, Typography } from '@woonstad/design-system';

const Footer = () => {
	const contact = useExternalLink('CONTACT');
	const website = useExternalLink('WEBSITE');
	const privacy = useExternalLink('PRIVACY_STATEMENT');
	const disclaimer = useExternalLink('USER_CONSENT_DISCLAIMER');

	return (
		<footer className={styles.root}>
			<div className={styles.wrapper}>
				<div className={styles.helpWrapper}>
					<InfoBlue />
					<div className={styles.helpTextWrapper}>
						<Typography
							variant='title-body'
							textColorClassname='text-base-primary-on-light'
						>
							<Trans>
								Kunnen we je helpen?
							</Trans>
						</Typography>
						<TextLink
							iconRight={ArrowForward}
							size='small'
						>
							<a href={contact}><Trans>Neem contact met ons op</Trans></a>
						</TextLink>
					</div>
				</div>
				<div className={styles.secondaryLinks}>
					<Typography
						variant='label-small'
					>
						<span>&copy; {new Date().getFullYear()}{' '}
							<a
								href={website}
								target='_blank'
								rel='noreferrer'
							>
								Stadswonen Rotterdam
							</a>
						</span>
					</Typography>
					&minus;
					<Typography variant='label-small'>
						<a
							href={privacy}
							target='_blank'
							rel='noreferrer'
						>
							Privacy Statement
						</a>
					</Typography>
					&minus;
					<Typography variant='label-small'>
						<a
							href={disclaimer}
							target='_blank'
							rel='noreferrer'
						>
							Disclaimer
						</a>
					</Typography>
				</div>

			</div>
		</footer>
	);
};

export default Footer;
