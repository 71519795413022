import { useQuery } from '@tanstack/react-query';
import { EnrollmentService } from '../../../../Shared.Frontend/generated-portal-stadswonen/services/EnrollmentService';
import { useAccount } from '@azure/msal-react';

export const enrolledStatusMap: Record<NonNullable<0 | 1 | 2 | 3>, string> = {
	0: 'NOT_ENROLLED',
	1: 'ENROLLED',
	2: 'WAITING_FOR_PAYMENT',
	3: 'PAYMENT_FAILED',
} as const;

export const enrolledStatusValue = (enrolledStatus?: keyof typeof enrolledStatusMap) => {
	if (enrolledStatus === undefined) {
		return '';
	}

	switch (true) {
		case enrolledStatusMap[enrolledStatus] === 'NOT_ENROLLED':
			return 'NOT_ENROLLED';
		case enrolledStatusMap[enrolledStatus] === 'ENROLLED':
			return 'ENROLLED';
		case enrolledStatusMap[enrolledStatus] === 'WAITING_FOR_PAYMENT':
			return 'WAITING_FOR_PAYMENT';
		case enrolledStatusMap[enrolledStatus] === 'PAYMENT_FAILED':
			return 'PAYMENT_FAILED';
		default:
			return '';
	}
};

export const useIsEnrolled = () => {
	const account = useAccount();
	const { data, isError, isLoading, refetch } = useQuery({
		queryKey: ['enrollment-is-enrolled'],
		queryFn: EnrollmentService.enrollmentIsEnrolled,
		enabled: Boolean(account?.idToken),
		cacheTime: 0,
	});

	return {
		data,
		isEnrolled: Boolean(
			enrolledStatusValue(data) === 'ENROLLED'
			// If account is already a customer / has contracts - they are enrolled
			|| account?.idTokenClaims?.extension_Roles?.includes('customer'),
		),
		isError,
		isLoading,
		refetch,
	};
};
