import { FunctionComponent } from 'react';
import SkeletonGradient from '../SkeletonGradient';
import './TextSkeleton.scss';

const TextSkeleton: FunctionComponent = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			className='text-skeleton'
		>
			<defs>
				<SkeletonGradient
					id='textDark'
					color='dark'
				/>
			</defs>

			<clipPath id='textDarkMask'>
				<rect
					x='0'
					y='0'
					width='100%'
					height='20'
				/>
				<rect
					x='0'
					y='25'
					width='80%'
					height='20'
				/>
				<rect
					x='0'
					y='50'
					width='60%'
					height='20'
				/>
			</clipPath>
			<rect
				width='100%'
				height='100%'
				clipPath='url(#textDarkMask)'
				fill='url(#textDark)'
			/>
		</svg>
	);
};

export default TextSkeleton;
