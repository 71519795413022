import { FunctionComponent } from 'react';
import styles from './TextInputSkeleton.module.scss';
import SkeletonGradient from '../SkeletonGradient';

const TextInputSkeleton: FunctionComponent = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			className={styles.rows}
		>
			<defs>
				<SkeletonGradient
					id='textInputSkeletonDark'
					color='light'
				/>
			</defs>

			<clipPath id='textInputSkeletonDarkMask'>
				<rect
					x='0%'
					y='0'
					width='100%'
					height='48px'
				/>
			</clipPath>
			<rect
				width='100%'
				height='100%'
				clipPath='url(#textInputSkeletonDarkMask)'
				fill='url(#textInputSkeletonDark)'
			/>
		</svg>
	);
};

export default TextInputSkeleton;
