import dayjs from 'dayjs';
import { contractTypeTitle } from 'constants/contract';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ContractService } from '../../../../Shared.Frontend/generated-portal-stadswonen/services/ContractService';
import { ContractListItem } from '../../../../Shared.Frontend/generated-portal-stadswonen/models/ContractListItem';

const endDateFilter = (contract: ContractListItem) => {
	if (contract.endDate !== null) {
		return dayjs(contract.endDate).isAfter(dayjs());
	}

	return true;
};

export const useGetContracts = () => {
	const query = useQuery({
		queryKey: ['contracts'],
		queryFn: ContractService.contractList,
	});
	return query;
};

export const useContracts = () => {
	const { data, isSuccess, isLoading, isError, error } = useGetContracts();
	return {
		success: isLoading ? undefined : isSuccess && !data?.error && !isError,
		isError,
		requesting: isLoading,
		error: data?.error || error,
		contracts: data?.contracts,
	};
};

export const useTwoOtherContracts = (id: string) => {
	const { success, contracts } = useContracts();
	const otherContracts = useMemo(
		() =>
			contracts &&
			contracts.reduce((acc, contract) => {
				if (String(contract.id) !== id && acc.length < 2) {
					return [...acc, contract];
				}

				return acc;
			}, [] as ContractListItem[]),
		[contracts, id],
	);
	return { success, otherContracts };
};


export const useContractById = (id?: string | number) => {
	const { success, contracts } = useContracts();

	const contract = useMemo(() => contracts && contracts.find((contract) => String(contract.id) === id), [
		contracts,
		id,
	]);

	return { success, contract };
};

export const useContractsOptions = () => {
	const { success, contracts } = useContracts();
	const options = useMemo(() => (
		contracts !== undefined
			? contracts
				.filter(endDateFilter)
				.map((contract) => ({
					value: String(contract.id),
					label: `${contractTypeTitle(contract.type)} - ${contract.address}`,
				}), [])
			: []
	), [contracts]);

	return { success, options };
};

type UseHasNewContracts = () => boolean;

export const useHasNewContracts: UseHasNewContracts = () => {
	const { contracts } = useContracts();
	const contract = useMemo(() => {
		if (contracts) {
			return contracts.find((contract) => {
				const diff = dayjs().diff(dayjs(contract.startDate), 'months');
				return diff <= 2;
			});
		}

		return false;
	}, [contracts]);
	return contract !== undefined;
};

export const useAllContractsExpired = () => {
	const { contracts } = useContracts();
	const expired = useMemo(() => {
		if (contracts) {
			return contracts.filter((contract) => {
				if (contract.endDate !== null) {
					return dayjs(contract.endDate).isBefore(dayjs());
				}

				return false;
			});
		}

		return [];
	}, [contracts]);
	return contracts === undefined ? undefined : expired.length === contracts.length;
};
