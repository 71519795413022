import clsx from 'clsx';
import { CSSProperties, forwardRef, ReactNode, Ref } from 'react';
import { Link } from 'react-router-dom';
import './Box.scss';
import Anchor, { AnchorProps } from 'components/anchor/Anchor';
import { LocationDescriptor } from 'history';
import parseLink from 'utils/parseLink';

export interface BoxProps extends Pick<AnchorProps, 'disableModal' | 'target' | 'rel' | 'id'> {
	children: ReactNode;
	className?: string;
	inline?: boolean;
	noPadding?: boolean;
	variant?: 'light' | 'dark' | 'soft-dark' | 'warning' | 'green' | 'soft-shadow' | 'soft-red';
	noShadow?: boolean;
	shadowOnHover?: boolean;
	noBackground?: boolean;
	style?: CSSProperties;
	link?: LocationDescriptor;
	targetBlank?: boolean;
	hover?: boolean;
	onClick?: () => void;
	id?: string;
	testid?: string;
}

const Box = forwardRef((props: BoxProps, ref?: Ref<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | undefined>) => {
	const {
		children,
		className,
		inline = false,
		noPadding = false,
		noShadow = false,
		shadowOnHover = false,
		noBackground = false,
		variant = 'light',
		style,
		link,
		targetBlank = false,
		hover = false,
		onClick,
		id,
		testid,
		...restProps
	} = props;
	const classes = clsx('box', className, {
		'box--inline': inline,
		'box--no-padding': noPadding,
		'box--no-shadow': noShadow,
		'box--shadow-on-hover': shadowOnHover,
		'box--no-background': noBackground,
		'box--hover': hover || link !== undefined || onClick !== undefined,
		[`box--variant-${variant}`]: variant !== 'light',
	});

	const { isExternalLink, pathname } = parseLink(link);

	if (pathname !== undefined && !isExternalLink) {
		return (
			<Link
				id={id}
				to={pathname}
				{...restProps}
				className={classes}
				style={style}
				innerRef={ref as Ref<HTMLAnchorElement>}
				target={targetBlank ? '__blank' : undefined}
				onClick={onClick}
				data-testid={testid}
			>
				{children}
			</Link>
		);
	}

	if (pathname !== undefined && isExternalLink) {
		return (
			<Anchor
				id={id}
				href={pathname}
				{...restProps}
				className={classes}
				style={style}
				ref={ref as Ref<HTMLAnchorElement>}
				target={targetBlank ? '__blank' : undefined}
				onClick={onClick}
				data-testid={testid}
			>
				{children}
			</Anchor>
		);
	}

	if (onClick !== undefined) {
		return (
			<button
				id={id}
				className={classes}
				style={style}
				ref={ref as Ref<HTMLButtonElement>}
				onClick={onClick}
				data-testid={testid}
			>
				{children}
			</button>
		);
	}

	return (
		<div
			id={id}
			className={classes}
			style={style}
			ref={ref as Ref<HTMLDivElement>}
			data-testid={testid}
		>
			{children}
		</div>
	);
});

Box.displayName = 'Box';

export default Box;
