import Box from 'components/box/Box';
import useMedia from 'hooks/useMedia';
import Header from 'components/header/Header';
import { FunctionComponent, ReactNode } from 'react';
import styles from './CenterBoxedContainer.module.scss';

interface Props {
	children?: ReactNode;
	showBox?: boolean;
}

const CenterBoxedContainer: FunctionComponent<Props> = ({
	children,
	showBox = true,
}) => {
	const [mobile] = useMedia('(max-width: 575.98px)');

	return (
		<div className={styles.root}>
			<div className={styles.background}>
				<div className={styles.backgroundShapes} />
			</div>

			<div className={styles.headerWrapper}>
				<Header />
			</div>

			<div className={styles.content}>
				{showBox ? (
					<Box
						noShadow={mobile}
						noPadding
						className={styles.box}
					>
						{children}
					</Box>
				) : (
					children
				)}
			</div>
		</div>
	);
};

export default CenterBoxedContainer;
