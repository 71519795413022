import { useAccount, useMsal } from '@azure/msal-react';
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import getDomain from '../../../Shared.Frontend/utils/getDomain';

export const useLogoutAction = () => {
	const account = useAccount();
	const { instance } = useMsal();
	const [, , removeCookie] = useCookies(['x-auth-token']);

	const logout = useCallback(() => {
		const domain = `.${getDomain(location.host)}`;

		// Tokens is still valid after logging out for some time, so we need to remove the cookie manually
		// Otherwise the website will still show the user as logged in
		removeCookie('x-auth-token', {
			path: '/',
			domain,
		});

		instance.logout({
			idTokenHint: account?.idToken,
		});
	}, [account?.idToken, instance, removeCookie]);

	return logout;
};
